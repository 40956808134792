import React, { Component, Fragment } from 'react';

import { ParallaxComponent } from '../common';

import AboutImage from '../../assets/img/about/about-pic.jpg';
import LogoImage from '../../assets/img/ui/logo-yellow.jpg';

import './styles.styl';

export default class SectionAbout extends Component {
  renderLogoAndTitle() {
    const { wordings } = this.props;

    return (
      <ParallaxComponent index={10} className="title-container">
        <div className="animated-logo title-element" />
        <div className="title-element">
          <h2>{wordings.about.title_line1}</h2>
          <h1>{wordings.about.title_line2}</h1>
        </div>
      </ParallaxComponent>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderPicture() {
    return (
      <ParallaxComponent index={8}>
        <div>
          <img className="about-pic" src={AboutImage} alt="" />
        </div>
      </ParallaxComponent>
    );
  }

  renderAddresses() {
    const { wordings } = this.props;

    return (
      <Fragment>
        <address>
          <h3>{wordings.about.address_title}</h3>
          <span
            dangerouslySetInnerHTML={{ __html: wordings.about.address_content }}
          />
        </address>

        <address>
          <h3>{wordings.about.distrib_title}</h3>
          {wordings.about.distrib_content}
          <a href={`mailto:${wordings.about.distrib_mail}`}>
            {wordings.about.distrib_mail}
          </a>
        </address>
      </Fragment>
    );
  }

  renderDedicaceAndLogo() {
    const { wordings } = this.props;

    return (
      <div>
        <a href="http://yellowinnovation.fr/" target="_blank">
          <img src={LogoImage} alt="" className="logo" />
        </a>
        <a href="http://www.paulinedeltour.com/" target="_blank">
          <p className="dedicace">{wordings.about.dedicace}</p>
        </a>
      </div>
    );
  }

  renderContent() {
    const { wordings } = this.props;

    return (
      <div className="content-wrapper">
        <p>
          {wordings.about.desc}
          <br />
          <br />
          <a href="http://yellowinnovation.fr" target="_blank">
            yellowinnovation.fr
          </a>
        </p>
        {this.renderAddresses()}
        <div className="separation" />
        {this.renderDedicaceAndLogo()}
      </div>
    );
  }

  render() {
    return (
      <section className="about">
        {this.renderLogoAndTitle()}
        <div className="wrapper">
          {this.renderPicture()}
          <ParallaxComponent index={20}>
            {this.renderContent()}
          </ParallaxComponent>
        </div>
      </section>
    );
  }
}
