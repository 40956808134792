import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../components/layout/layout';
import {
  NavBar,
  Menu,
  Footer,
  Basket,
  pageWithNavigation,
} from '../components/common';

import AboutSection from '../components/about';

const AboutPage = ({ data }) => (
  <Layout
    title={data.wordings.fr.about.page_title}
    description={data.wordings.fr.about.page_description}
  >
    <NavBar withLogo basketClassName="yellow" />
    <Basket />
    <Menu />
    <AboutSection wordings={data.wordings.fr} />
    <Footer />
  </Layout>
);

export default pageWithNavigation(AboutPage);

AboutPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export const query = graphql`
  query AboutPageQuery {
    wordings {
      fr {
        about {
          page_title
          description
          title_line1
          title_line2
          address_title
          address_content
          distrib_title
          distrib_content
          distrib_mail
          dedicace
          desc
        }
      }
    }
  }
`;
